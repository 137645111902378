export const ActionType = {
    LOGIN_WITH_NUMBER: 'LOGIN_WITH_NUMBER',
    LOGIN_WITH_FACEBOOK: 'LOGIN_WITH_FACEBOOK',
    LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
    LOG_OUT: "LOG_OUT",
    GET_BANNER_LIST: 'GET_BANNER_LIST',
    TOURNAMENTS: 'TOURNAMENTS',
    PREMIUMGAME: "PREMIUMGAME",
    GAME_CATEGORY: "GAME_CATEGORY",
    GET_USERPROFILE: 'GET_USERPROFILE',
    WHITE_THEM: 'WHITE_THEM',
    DARK_THEM: 'DARK_THEM',
    GET_LOYALTY_COINS: "LOYALTY_COINS",
    GET_TOP_ASTROLOGERS: 'GET_TOP_ASTROLOGERS',
    FREE_SESSION: 'FREE_SESSION',
    DAILY_HOROSCOPE: 'DAILY_HOROSCOPE',
    GET_ASTRO_VIDEOS: "GET_ASTRO_VIDEOS",
    GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
    GET_USERMSISDN: 'GET_USERMSISDN'

}
